<template>
    <div class="container-fluid">
        <div v-if="!view_isDcs" class="row  px-2 pt-2">
            <div class="col-lg-9 col-8 ">
                <div class="row ">
                    <div class="col-12 ">
                        <div class="white-board">
                            <div class="white-board-title">巡檢即時資料
                                <div style="color: tomato;"> {{ GasPositionTest }}</div>
                                <div class="col-12">
                                    <button v-if="loadJsonStop" @click="loadJsonStop = false; loadNextJson()"
                                        style="margin-left: 0px;" class="search-btn ">
                                        繼續載入
                                    </button>
                                    <button v-if="!loadJsonStop" @click="loadJsonStop = true" style="margin-left: 0px;"
                                        class="search-btn ">
                                        停止載入
                                    </button>
                                    <!-- <h2 style="color: #000;">roll_2 / xy對調 / theta + 1.57</h2> -->
                                </div>
                            </div>
                            <div class="canvas-wrap-5G">
                                <canvas width="1720" height="1080" id="three-5G"></canvas>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 ">
                        <div class="white-board">
                            <div class="white-board-title">Flir a400</div>
                            <!-- <img v-if="GasCameraFlia" :src="'data:image/jpeg;base64, ' + GasCameraFlia"> -->
                            <img src="img_02.jpg">
                            <!-- <p class="noPictures" v-if="!GasCameraFlia">No pictures yet</p> -->
                        </div>
                    </div>
                    <div class="col-6 ">
                        <div class="white-board">
                            <div class="white-board-title">360環景</div>
                            <!-- <img v-if="GasCamera360" :src="'data:image/jpeg;base64, ' + GasCamera360"> -->
                            <img src="img_01.jpg">
                            <!-- <p class="noPictures" v-if="!GasCamera360">No pictures yet</p> -->
                        </div>
                    </div>
                    <div class="col-12">
                        <button @click="simulateY_L()" style="margin-left: 80px;"
                            class="simulateBtn btn btn-primary ">上</button>
                    </div>
                    <div class="col-12">
                        <button @click="simulateX_L()" class="simulateBtn btn btn-primary ">左 </button>
                        <button @click="simulateX_R()" class="simulateBtn btn btn-primary ">右 </button>
                    </div>
                    <div class="col-12">
                        <button @click="simulateY_R()" style="margin-left: 80px;" class="simulateBtn btn btn-primary ">下
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-4 ">
                <div class="white-board">
                    <div class="white-board-title">DCS即時資料</div>
                    <div class="dcs-list" v-for="dcs in DcsList" :key="dcs.id">
                        <div class="dcs-list-title">{{ dcs.sensor }}</div>
                        <div class="dcs-list-info">
                            <span class="dcs-list-info-value">{{ dcs.value }}</span>
                            <span class="dcs-list-info-unit">{{ dcs.unit }} </span>
                        </div>
                    </div>
                    <!-- <button class="search-btn" @click="view_isDcs = true" style="
                        padding: 3px 15px;
                        width: 100%;
                        height: 35px;
                        letter-spacing: .075em;
                        font-weight: 500;">
                        回DCS主頁面
                    </button> -->
                    <button class="search-btn" onclick="location.href='https://sis-dev.ioc.xadm.tw/'" style="
                        padding: 3px 15px;
                        width: 100%;
                        height: 35px;
                        letter-spacing: .075em;
                        font-weight: 500;">
                        回到雲平台
                    </button>
                </div>
            </div>
        </div>
        <div v-if="view_isDcs" class="row  px-2 pt-2">
            <!-- {{ DcsList }} -->
            <div class="dcs-view">
                <img class="dcs-view-img" src="/dcs.png" />
                <template v-for="_d in DcsList" :key="_d.id">
                    <span v-bind:class="_d.sensor" v-if="dcs.indexOf(_d.sensor) != -1">{{ _d.value + " " + _d.unit
                        }}</span>
                </template>
                <!-- 
                <span class="TT-206">TT-206</span>
                <span class="LI-203">LI-203</span>
                <span class="TI-260A">TI-260A</span>
                <span class="PI-260A">PI-260A</span>
                <span class="LCV-284">LCV-284</span>
                <span class="PI-260B">PI-260B</span>
                <span class="LI-260">LI-260</span>
                <span class="LIC-260">LIC-260</span>
                <span class="LI-262">LI-262</span> 
                -->
            </div>
            <button class="search-btn" @click="view_isDcs = false" style="
                padding: 3px 15px;
                width: 180px;
                height: 35px;
                letter-spacing: 0.075em;
                font-weight: 500;
                margin-top: 10px;
                margin-bottom: 30px;">
                回巡檢主頁面
            </button>
        </div>
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import * as THREE from 'three'
// import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader.js'
// import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js'
// import { ThreeMFLoader } from 'three/examples/jsm/loaders/3MFLoader.js'
import { PCDLoader } from 'three/examples/jsm/loaders/PCDLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

// import { MTLLoader, OBJLoader } from "three-obj-mtl-loader";
export default {
    searchUserList: {},
    name: 'live',
    data() {
        return {
            GasPositionTest: { x: 0, y: 0, theta: 0 },
            dcs: ["TT-206", "LI-203", "TI-260A", "PI-260A", "LCV-284", "PI-260B", "LI-260", "LIC-260", "LI-262", "P-263A"],
            view_isDcs: false,
            stop: false,

            currentFileIndex: 0,  // 當前檔案的索引
            jsonFiles: [],  // 儲存所有的 json 檔案名 

            loadJsonStop: false
        };
    },
    watch: {
        view_isDcs: function (val) {
            if (val == false) {
                this.stop = false;
                var self = this;
                setTimeout(function () {
                    self.initThree();
                }, 50)
            } else {
                this.stop = true;
            }
        }

    },
    mounted() {
        this.initThree()
        // 初始化 JSON 檔案列表 (例如 0910131 ~ 091911)
        this.initializeJsonFiles();
        // 開始加載第一個檔案
        this.loadNextJson();
    },
    computed: {
        ...mapState(
            {
                GasEvent: state => state.gas.event,
                GasOdometry: state => state.gas.odometry,
                GasPosition: state => state.gas.position,
                GasStatus: state => state.gas.status,
                GasCameraFlia: state => state.gas.cameraFlia,
                GasCamera360: state => state.gas.camera360,
                DcsList: state => state.dcs.list,
            }
        ),
    },
    methods: {
        ...mapActions([
            'GetDropDownOrgList',
            'GetDropDownAreaList',
            'GetDropDownTankList',
            'GetSearchDisplayList',
        ]),
        simulateX_L() {
            if (this.GasPositionTest) {
                this.GasPositionTest.x -= 1;
            }
        },
        simulateX_R() {
            if (this.GasPositionTest) {
                this.GasPositionTest.x += 1;
            }
        },
        simulateY_L() {
            if (this.GasPositionTest) {
                this.GasPositionTest.y -= 1;
            }
        },
        simulateY_R() {
            if (this.GasPositionTest) {
                this.GasPositionTest.y += 1;
            }
        },
        initThree() {
            var self = this;
            const scene = new THREE.Scene();
            scene.background = new THREE.Color('#999');
            const canvas = document.querySelector('#three-5G');
            const renderer = new THREE.WebGLRenderer({ canvas, antialias: true });
            renderer.premultipliedAlpha = false;
            renderer.setSize(window.innerWidth, window.innerHeight);
            renderer.outputEncoding = THREE.sRGBEncoding;

            const camera = new THREE.PerspectiveCamera(20, window.innerWidth / window.innerHeight, 1, 10000);
            camera.position.set(0, 540, 0);

            const pointLight = new THREE.PointLight(0xfffffff);
            const ambientLight = new THREE.AmbientLight(0xfffffff);
            pointLight.position.set(20, 5, 5);
            scene.add(pointLight, ambientLight);

            const controls = new OrbitControls(camera, renderer.domElement);

            /*---- Polar Grid Helper ----*/
            const radius = 50;
            const radials = 4;
            const circles = 5;
            const divisions = 64;
            const helper = new THREE.PolarGridHelper(radius, radials, circles, divisions, "#666", "#444");
            scene.add(helper);

            /*---- Arrow ----*/
            const arrow = new THREE.ConeGeometry(1, 3.5, 30);
            const arrowMaterial = new THREE.MeshBasicMaterial({ color: "red" });
            const cone = new THREE.Mesh(arrow, arrowMaterial);
            scene.add(cone);

            /*---- 定義點雲變數 ----*/
            let points = null;
            let processedPoints = new Set();  // 用來記錄已經變成淺綠色的點

            /*---- PCDLoader ----*/
            const pdcLoader = new PCDLoader();
            pdcLoader.load('./3f_0919_norotate.pcd', function (loadedPoints) {
                loadedPoints.material.transparent = true; // 開啟透明效果
                loadedPoints.material.vertexColors = true; // 開啟每頂點著色
                points = loadedPoints; // 將載入的點雲賦值給 points 變數
                // points.rotation.set(2 * Math.PI * (180 / 360), 2 * Math.PI * (0 / 360), 2 * Math.PI * (0 / 360));
                scene.add(points);
            });

            function animate() {
                // 更新箭頭位置
                if (self.GasPositionTest) {
                    var _p = self.GasPositionTest;
                    let theta = self.GasPositionTest.theta || 0;  // 確保有 theta 的值
                    cone.position.set(_p.x, 0, _p.y);

                    // 使用 theta 來設置箭頭的旋轉角度
                    // theta 是繞著 y 軸的旋轉（假設 y 軸是上下方向）
                    cone.rotation.set(0, theta + 1.57, 2 * Math.PI * (90 / 360));  // 將角度應用到箭頭的旋轉
                }

                // 確保點雲已載入
                if (points) {
                    const positions = points.geometry.attributes.position;
                    const colors = points.geometry.attributes.color;

                    // 遍歷每個頂點，根據距離更新顏色和透明度
                    for (let i = 0; i < positions.count; i++) {
                        const x = positions.getX(i);
                        const z = positions.getZ(i);
                        const dx = x - cone.position.x;
                        const dz = z - cone.position.z;
                        const distance = Math.sqrt(dx * dx + dz * dz);

                        if (distance <= 5) {
                            // 設定為深綠色且半透明（當下範圍）
                            colors.setXYZ(i, 0.0, 0.5, 0.0);  // 深綠色
                            points.material.opacity = 0.7;     // 半透明
                        } else if (processedPoints.has(i)) {
                            // 已經經過的點設置為淺綠色
                            colors.setXYZ(i, 0.3, 1.0, 0.3);  // 淺綠色
                            points.material.opacity = 0.5;     // 半透明
                        }

                        // 如果距離小於等於 10 且該點還未被處理過，將其加入歷史
                        if (distance <= 5 && !processedPoints.has(i)) {
                            processedPoints.add(i);
                        }
                    }
                    colors.needsUpdate = true; // 告知 Three.js 更新顏色屬性
                }

                renderer.render(scene, camera);
                if (!self.stop) requestAnimationFrame(animate);
                controls.update();
            }

            animate();
        },
        // 初始化檔案名
        initializeJsonFiles() {
            for (let i = 72700; i <= 73408; i++) {
                this.jsonFiles.push(`1${i}.json`);
                if (i % 100 == 59) i += 40;
            }

            console.log(this.jsonFiles)
        },

        // 動態載入下一個 JSON 檔案
        loadNextJson() {

            console.log('動態載入下一個 ing', this.currentFileIndex < this.jsonFiles.length)
            if (this.currentFileIndex < this.jsonFiles.length) {

                const fileName = this.jsonFiles[this.currentFileIndex];

                console.log(`1${fileName}`)
                fetch(`./mary_json_roll_2/${fileName}`)
                    .then(response => response.json())
                    .then(data => {
                        this.updateConePosition(data.GasPosition);
                        this.currentFileIndex++;
                        // 繼續加載下一個檔案

                        if (!this.loadJsonStop)
                            setTimeout(this.loadNextJson, 100); // 延遲1秒 
                    })
                    .catch(error => {
                        console.error(`Error loading ${fileName}:`, error);
                    });
            }
        },

        // 更新 cone 位置
        updateConePosition(position) {
            this.GasPositionTest.x = position.y;
            this.GasPositionTest.y = position.x;
            this.GasPositionTest.theta = position.theta;
            // 您的 Three.js 動畫循環會自動更新場景中的箭頭位置
        },
    }
}
</script>