<template>
    <div class="container-fluid">
        <div class="row  px-2 pt-2">
            <div class="col-12">
                <div class="white-board vehicle-block">
                    <div class="white-board-title">載具資訊</div>
                    <div class="row  px-2">
                        <div class="col-4 col-md-2">
                            <div class="car-img-col">
                                <img src="/car.png" />
                                <div>{{ GasOdometry.task_name }}</div>
                            </div>
                        </div>
                        <div class="col-8 col-md-2">
                            <div style="
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                            ">
                                <div>
                                    <h6 class="mt-5 mb-3">{{ GasOdometry.task_name }}</h6>
                                </div>
                                <p><span>電量：</span> {{ GasStatus.bettery_level + '%' }}</p>
                                <p><span>狀態：</span> {{ GasStatus.status }}</p>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <p><span>CPU 負載：</span> {{ GasStatus.cpu_loading }}</p>
                            <p><span>CPU 溫度：</span> {{ GasStatus.cpu_temperature }}</p>
                            <p><span>電流：</span> {{ GasStatus.electric_current }}</p>
                            <p><span>是否充電：</span> {{ GasStatus.is_charging }}</p>
                            <p><span>是否定位：</span> {{ GasStatus.is_positioned }}</p>
                            <p><span>目前使用網路（主要/備援）：</span> {{ GasStatus.net }}</p>
                            <p><span>車體角度：</span> {{ GasPosition.theta }}</p>
                        </div>
                        <div class="col-12 col-md-4">
                            <p><span>車體位置 X：</span> {{ GasPosition.x }}</p>
                            <p><span>車體位置 Y：</span> {{ GasPosition.y }}</p>
                            <p><span>任務名稱：</span> {{ GasOdometry.task_name }}</p>
                            <p><span>累積里程：</span> {{ GasOdometry.acc_distance }}</p>
                            <p><span>事件代碼：</span> {{ GasEvent.code }}</p>
                            <p><span>事件名稱：</span> {{ GasEvent.message }}</p>
                            <p><span>事件層級：</span> {{ GasEvent.level }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
    searchUserList: {},
    name: 'Vehicle',
    computed: {
        ...mapState(
            {
                GasEvent: state => state.gas.event,
                GasOdometry: state => state.gas.odometry,
                GasPosition: state => state.gas.position,
                GasStatus: state => state.gas.status,
                GesGasCameraFlia: state => state.gas.cameraFlia,
                GasCamera360: state => state.gas.camera360,
                DcsList: state => state.dcs.list,
            }
        ),
    },
    data() {
        return {
            view_isDcs: false,
            stop: false,
            status: {
                "bettery_level": 95,
                "cpu_loading": 14,
                "cpu_temperature": 39,
                "electric_current": 200,
                "is_charging": 0,
                "is_positioned": 0,
                "net": "secondary",
                "status": "not_ready",
                "ts": 1677659557
            },
            odometry: {
                "acc_distance": 20.23,
                "net": "primary",
                "task_name": "clean_zone_1",
                "ts": 1677659556.511072
            },
            position: {
                "map_filename": "20230407_121300.png",
                "net": "secondary",
                "theta": 0.92,
                "ts": 1677659557,
                "x": 11.5,
                "y": 10.5
            },
            event: {
                "code": "200002",
                "level": "warn",
                "message": "偵測到障礙物，停止定位。",
                "net": "primary",
                "ts": 1677659556.511072
            }

        };
    },
    watch: {

    },
    mounted() {
    },
    methods: {
    }
}
</script>